/** Subscription page allows the user to see
 * the list of their subscriptions for VODIUM
 * ENDPOINT -> /subscriptions */

import React, { useEffect, useState } from "react";
import WelcomeName from "../components/WelcomeName";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { callMsGraph } from "../services/msGraphApiCall";
import { loginRequest } from "../services/authProvider";
import {
  listAvailableSubscription,
  activateSubscription,
  cancelSubscriptionHandler,
  listAvailablePlan,
} from "../services/subscriptions";
import Table, { InlineMenu, StatusIndicator } from "../components/Table";
import { SubscriptionsConfig } from "../configs/subscription";
import { ActionTypes, StatusKeys } from "../shared/common/constant";
import { Modal } from "../shared/Modal";
import Form from "../shared/Form";
import { showAlert } from "../shared/Alert";
import { connect } from "react-redux";

const Subscriptions = (jwt) => {
  const [subscriptionData, setSubscriptionData] = React.useState([]);
  console.log(subscriptionData);
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = React.useState(null);
  const [isChangeModalOpen, setIsChangeModalOpen] = React.useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

  const [subscriptionId, setSubscriptionId] = React.useState([]);
  const [subscriptionPlanConfig, setSubscriptionPlanConfig] = useState(
    SubscriptionsConfig.ChangePlanForm
  );
  // TODO: Update link to Microsoft Store listing
  const navigateToStore = () => {
    window.open("https://vodium.com/buy-now", "_blank", "noreferrer");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Subscription ID",
        accessor: "id",
      },
      {
        Header: "Plan ID",
        accessor: "planId",
      },
      {
        Header: "Subscription Name",
        accessor: "name",
      },
      {
        Header: "Purchaser Email",
        accessor: (row) => row.purchaser.emailId,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Status",
        accessor: "saasSubscriptionStatus",
        Cell: StatusIndicator,
      },
      {
        id: "action-column",
        Cell: (props) => {
          const menuOptions = [...SubscriptionsConfig.tableMenuOptions];
          if (
            props.row.original.saasSubscriptionStatus === StatusKeys.SUBSCRIBED
          ) {
            menuOptions.push({
              id: 1,
              value: "Change Plan",
            });
            menuOptions.push({
              id: 2,
              value: "Cancel Subscription",
            });
          } else if (
            props.row.original.saasSubscriptionStatus === StatusKeys.PENDING
          ) {
            menuOptions.push({
              id: 3,
              value: "Activate",
            });
          }
          return InlineMenu({
            options: [...menuOptions],
            selectedItem: (option) => onMenuOptionSelect(option, props),
          });
        },
      },
    ],
    []
  );
  const onMenuOptionSelect = (payload, props) => {
    const rowData = { ...props.row.original };
    console.log("rowData", rowData);
    switch (payload.id) {
      case 1:
        console.log("CHANGE PLAN");
        // console.log("ROWDATTAAA", rowData);
        openChangeSubscriptionModal(props.row.original);
        openChangeModal();
        break;
      case 2:
        openCancelModal(rowData);
        break;
      case 3:
        console.log("ACTIVATE");
        activateSubscriptionHandler(rowData);
        break;
      default:
        return;
    }
  };

  const closeChangeModal = () => {
    setIsChangeModalOpen(false);
  };

  const openChangeModal = () => {
    setIsChangeModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const openCancelModal = (data) => {
    console.log("openCancelModal#data>>>", data);
    setIsCancelModalOpen(true);
    setSubscriptionId(data.id);
  };

  const actionHandler = async ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        closeChangeModal();
        closeCancelModal();
        break;
      case ActionTypes.buttonClick:
        openChangeModal();
        openCancelModal();
        break;
      case ActionTypes.formSubmit:
        changePlan();
        break;
      default:
        return;
    }
  };

  React.useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
    getSubscriptionData();
    console.log("Graph Data =>", graphData);
    // eslint-disable-next-line
  }, []);

  /* getSubscriptionData - gets the list of subscriptions purchased by the user */
  const getSubscriptionData = async () => {
    const { jwt: accessToken } = jwt
    const response = await listAvailableSubscription(accessToken.slice(7));
    const subscriptionResponse = response.subscriptions;
    setSubscriptionData(subscriptionResponse);
  };

  // /* activateSubscription - handles activating the subscription from the Subscriptions page */
  const activateSubscriptionHandler = async (data) => {
    // const rowData = { ...props.row.original };
    // /* id is the Subscription ID */
    // rowData.id = Number(rowData.id);
    await activateSubscription(data.id, data.planId);
    showAlert({
      type: ActionTypes.successAlert,
      text: "Subscription activated.",
    });
    getSubscriptionData();
  };

  /* changePlan - handles changing the plan of an existing subscription */
  const changePlan = async (accessToken, SUBSCRIPTION_ID) => {
    // console.log("ROWDATA", rowData.id);
    /* id is the Subscription ID */
    // rowData.id = Number(rowData.id);
    showAlert({
      type: ActionTypes.successAlert,
      text: "Plan updated.",
    });
    console.log("updating plan");
    getSubscriptionData();
    closeChangeModal();
  };

  const displayAlert = (type, text) => {
    showAlert({
      type: type,
      text: text,
    });
  };

  const cancelSubscription = async () => {
    try {
      await cancelSubscriptionHandler(subscriptionId);
      displayAlert(ActionTypes.successAlert, "Subscription cancelled.");
      await getSubscriptionData();
      closeCancelModal();
    } catch (error) {
      displayAlert(
        ActionTypes.errorAlert,
        `Failed to cancel subscription: ${error.message}`
      );
    }
  };

  const openChangeSubscriptionModal = async (subscriptionData) => {
    const response = await listAvailablePlan(subscriptionData.id);

    const selectedOption = subscriptionData.planId;

    console.log(selectedOption);
    const options = response.plans.map((plan) => {
      return { displayName: plan.displayName, value: plan.planId };
    });
    const fields = [
      {
        ...SubscriptionsConfig.ChangePlanForm.fields[0],
        options,
        selectedOption,
      },
    ];

    setSubscriptionPlanConfig({
      ...SubscriptionsConfig.ChangePlanForm,
      subscriptionId: subscriptionData.id,
      fields,
    });
  };

  return (
    <>
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <main>
          <AuthenticatedTemplate>
            <div className="pt-20 pb-10 md:pt-28">
              <div className="px-6 mx-auto">
                <div className="md:items-center md:flex">
                  <WelcomeName viewpage={"HOME"} />
                  <p className="mt-1 text-base font-regular text-gray-500 md:mt-0 md:ml-2">
                    Here you can view, manage, and cancel your existing
                    subscriptions at any time.{" "}
                  </p>
                  <p></p>
                </div>
                <div>
                  <p className="mt-1 text-base font-regular text-gray-500 md:mt-2 ">
                    If this is your first time activating your subscription,
                    please wait/refresh until your subscription status is Green
                    and says “Subscribed.” This can take up to one minute and
                    you may need to refresh the page. Once your subscription is
                    active, you can head over to the{" "}
                    <a
                      className="text-blue-600"
                      target="_blank"
                      href="https://admin.microsoft.com/Adminportal/Home#/licenses"
                    >
                      Microsoft Admin Center
                    </a>{" "}
                    and assign your licenses to yourself or your team and start
                    using VODIUM.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-11">
              <div className="px-6 mx-auto h-screen">
                <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">
                  Subscription Details
                </p>
                <Table columns={columns} data={subscriptionData} />
              </div>
            </div>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <div className="pt-10 pb-10 md:pt-16 xl:pt-28">
              <div className="px-6 mx-auto">
                <div className="md:items-center md:flex"></div>
              </div>
            </div>
            <div className="px-6 mx-auto h-screen">
              <div className="space-y-5 sm:space-y-6">
                <div className="grid gap-5">
                  <div className="bg-white border border-gray-200 rounded">
                    <div className="px-5 py-4 center">
                      <svg
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM8.39747 17.4466C8.64413 17.7794 9.11385 17.8492 9.44661 17.6025C10.175 17.0627 11.0541 16.75 12 16.75C12.9459 16.75 13.825 17.0627 14.5534 17.6025C14.8862 17.8492 15.3559 17.7794 15.6025 17.4466C15.8492 17.1138 15.7794 16.6441 15.4466 16.3975C14.4742 15.6767 13.285 15.25 12 15.25C10.715 15.25 9.5258 15.6767 8.55339 16.3975C8.22062 16.6441 8.15082 17.1138 8.39747 17.4466ZM16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5ZM9 12C9.55228 12 10 11.3284 10 10.5C10 9.67157 9.55228 9 9 9C8.44772 9 8 9.67157 8 10.5C8 11.3284 8.44772 12 9 12Z"
                            fill="#1C274C"
                          ></path>{" "}
                        </g>
                      </svg>
                      <p>Sorry!</p>
                      <p>
                        You don't have an exisiting subscription to VODIUM.
                        Please visit the store to purchase.
                      </p>
                      <button
                        className="blue button view-button mr-3"
                        onClick={navigateToStore}
                      >
                        Go to the store
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </UnauthenticatedTemplate>
        </main>

        <Modal isOpen={isChangeModalOpen} onAction={actionHandler}>
          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-sm">
              <div className="">
                <p className="text-xl font-semibold text-gray-900">
                  {SubscriptionsConfig.ChangePlanForm.title}
                </p>
                <Form
                  className={"my-6"}
                  form={subscriptionPlanConfig}
                  onAction={actionHandler}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={isCancelModalOpen} onAction={actionHandler}>
          <div className="text-center my-5">
            <p className="text-lg font-semibold text-gray-900">
              Are you sure you want to cancel your subscription?
            </p>
            <div className="mt-8 space-x-5">
              <button
                type="button"
                onClick={() => {
                  closeCancelModal();
                }}
                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  cancelSubscription();
                }}
                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    jwt: state.app.accessToken,
  };
};
export default connect(mapStateToProps)(Subscriptions);
