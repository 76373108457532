import { API_ORIGIN, HTTP_METHODS } from "../shared/common/constant";
import HttpService from "./http.service";

const BASE_URL = "/email";
class EmailService {

    static sendEmailBug = async (body) => {
        try {
            const endpoint = API_ORIGIN + BASE_URL + "/bug";
            const response = await HttpService.sendRequest(
                endpoint,
                HTTP_METHODS.POST,
                body
            );

            const status = await response;
            return status.status;
        } catch (error) {
            throw Error("Failed to send email: " + error);
        }
    };

    static sendEmailContact = async (body) => {
        try {
            const endpoint = API_ORIGIN + BASE_URL + "/contact";
            const response = await HttpService.sendRequest(
                endpoint,
                HTTP_METHODS.POST,
                body
            );

            const status = await response;
            return status.status;
        } catch (error) {
            throw Error("Failed to send email: " + error);
        }
    };

    static sendEmailLicenseUpdate = async (body) => {
        try {
            const endpoint = API_ORIGIN + BASE_URL + "/license";
            const response = await HttpService.sendRequest(
                endpoint,
                HTTP_METHODS.POST,
                body
            );

            const status = await response;
            return status.status;
        } catch (error) {
            throw Error("Failed to send email: " + error);
        }
    };
}

export default EmailService;
