import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: '20a588e6-82e6-4cc5-a1ef-5024de88dd77',
        //'1f1bba24-9bba-4813-b90b-a861021846e8',,
        redirectUri: '/',
        // postLogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    }
};

export const loginRequest = {
    scopes: ["User.Read", "People.Read", "User.ReadBasic.All"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphSubscriptionEndpoint: "https://graph.microsoft.com/v1.0/subscriptions/",
    // graphPeopleEndpoint: "https://graph.microsoft.com/v1.0/me/people"
    graphPeopleEndpoint: "https://graph.microsoft.com/v1.0/users",
};