import { ActionTypes, FORM } from "../shared/common/constant";

export const SubscriptionsConfig = {
  tableMenuOptions: [],
  ChangePlanForm: {
    id: "changePlanForm",
    title: "Change Plan",
    fields: [
      {
        element: FORM.ELEMENTS.DROPDOWN,
        label: "Choose Plan",
        name: "planId",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "Choose plan",
        className: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Submit",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
};
