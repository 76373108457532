import { removeAlert, addAlert } from "../redux/app/app-action";
import store from "../redux/store";
import { alertInterval } from "./common/constant";

export const showAlert = ({ type, text }) => {
  store.dispatch(addAlert({ type, text }));
  setTimeout(() => {
    closeAlert(0);
  }, alertInterval);
}

const closeAlert = (index) => {
    store.dispatch(removeAlert({ index }));
}

export function Success({ children, className, index, ...rest }) {
  return (
    <div className='bg-green-100 rounded transition-all duration-500 mb-4'>
      <div className='p-3'>
        <div className='flex items-center justify-between'>
          <svg className='flex-shrink-0 w-5 h-5 text-green-500' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
              clipRule='evenodd'
            ></path>
          </svg>
          <p className='ml-3 text-sm font-medium text-green-900'>{children}</p>

          <div className='pl-3 ml-auto'>
            <button
              type='button'
              onClick={() => closeAlert(index)}
              className='inline-flex bg-green-100 rounded-lg -m1.5 p-1.5 text-green-500 hover:bg-green-200 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-100 focus:ring-green-500'
            >
              <svg
                className='w-4 h-4'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth='2'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12'></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Failure({ children, className, index, ...rest }) {
  return (
    <div className='rounded transition-all duration-500 bg-red-50 mb-4'>
      <div className='p-3'>
        <div className='flex items-center justify-between'>
          <svg className="flex-shrink-0 w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg"
               fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <p className='ml-3 text-sm font-medium text-red-700'>{children}</p>

          <div className='pl-3 ml-auto'>
            <button
              type='button'
              onClick={() => closeAlert(index)}
              className='inline-flex bg-red-50 rounded-lg -m1.5 p-1.5 text-red-500 hover:bg-red-100 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100 focus:ring-red-500'
            >
              <svg
                className='w-4 h-4'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth='2'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12'></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
