/** This is the Contact Us page where the customer will
 * be able to contact VODIUM support
 * It currently allows the customers to contact Vodium
 * thru email
 * ENDPOINT -> /contact */

import React from "react";
import "./css/Contact.css";
import "../App.css";
import ContactSupportForm from "./forms/ContactSupportForm";
import ReportBugForm from "./forms/ReportBugForm";
// import { SUPPORT_EMAIL } from '../shared/common/constant';

const Contact = () => {
  // This useState sets the initial view value for the page
  const [view, setView] = React.useState({
    id: "SUPPORT",
    display: "Contact Support",
  });

  const toggleView = (value) => {
    setView(value);
  };

  // const handleEmailUs = () => {
  //     const mailtoLink = `mailto:${SUPPORT_EMAIL}`;
  //     window.location.href = mailtoLink;
  // };

  React.useEffect(() => {
    // eslint-disable-next-line
  }, [view]);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden contact-page">
      <main>
        <div className="pt-20 pb-10 md:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="main-content-label">
                Contact Us
                {view.display === "" ? (
                  <span className="view-label"></span>
                ) : (
                  <span className="view-label">/ {view.display}</span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="px-6 mx-auto search-div">
          <div className="space-y-7 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  <div className="items-center justify-between mt-3">
                    <p className="none-gradient">
                      Connect with our{" "}
                      <span className="gradient-text">VODIUM</span> Team
                    </p>
                    <p className="text-xs md:text-sm">
                      Reach out to our small (but mighty) support team and we'll
                      get back to you within 24 hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-6">
          <div className="col-span-2">
            <div className="flex flex-col flex-1 overflow-x-hidden">
              <div className="py-2 mt-2 pb-auto">
                <div className="items-start">
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div
                          className="bg-white border border-gray-200 rounded support-options-div"
                          onClick={() =>
                            toggleView({
                              id: "SUPPORT",
                              display: "Contact Support",
                            })
                          }
                        >
                          <div className="px-5 py-4">
                            <div className="items-center justify-between">
                              <p className="text-base font-extrabold lg:text-lg">
                                Contact Support
                              </p>
                            </div>
                            <p className="text-xs">
                              Reach out to VODIUM Support Team.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* REPORT A BUG */}
                  {/* <div className="px-6 mx-auto">
                      <div className="space-y-5 sm:space-y-6">
                        <div className="grid gap-5">
                          <div
                            className="border border-gray-200 rounded support-options-div"
                            onClick={() =>
                              toggleView({ id: "BUG", display: "Report a Bug" })
                            }
                          >
                            <div className="px-5 py-4">
                              <div className="items-center justify-between">
                                <p className="support-options-label">
                                  Report a Bug
                                </p>
                              </div>
                              <p className="text-xs">
                                Raise a ticket for any encountered issues.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  {/* EMAIL US */}
                  {/* <div className="px-6 mx-auto">
                      <div className="space-y-5 sm:space-y-6">
                        <div className="grid gap-5">
                          <div
                            className="border border-gray-200 rounded support-options-div"
                            onClick={() => handleEmailUs()}
                          >
                            <div className="px-5 py-4 row">
                              <div className="column">
                                <div className="items-center justify-between">
                                  <p className="support-options-label">
                                    Email Us
                                  </p>
                                </div>
                                <p className="text-xs">
                                  Reach out directly via email.
                                </p>
                              </div>
                              <div className="column">
                                <svg
                                  className="icon"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 52 52"
                                  enableBackground="new 0 0 52 52"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <g>
                                      <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17 c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9 c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"></path>
                                      <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21 C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28 c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <div className="col-span-1 md:col-span-3">
              <div className="flex flex-col flex-1">
                <div className="py-2 mt-2 pb-24">
                  <div className="items-start">
                    <div className="px-6 mx-auto">
                      <div className="bg-white border border-gray-200 rounded">
                        <div className="space-y-8 sm:space-y-6">
                          <div className="grid gap-5">
                            <div className="items-center justify-between mt-3 mb-3">
                              {view.id === "SUPPORT" && (
                                <ContactSupportForm toggleView={toggleView} />
                              )}
                              {view.id === "BUG" && (
                                <ReportBugForm toggleView={toggleView} />
                              )}
                              {view.id === "EMAIL_SENT" && (
                                <div className="px-5 py-4 center">
                                  <svg
                                    width="80"
                                    height="80"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      strokeWidth="0"
                                    ></g>
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12 2C6.47715 2 2 6.47715 2 12C2 13.8153 2.48451 15.5196 3.33127 16.9883C3.50372 17.2874 3.5333 17.6516 3.38777 17.9647L2.53406 19.8016C2.00986 20.7933 2.72736 22 3.86159 22H12C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM15.6839 9.27047C16.0869 9.6482 16.1073 10.281 15.7295 10.6839L11.9795 14.6839C11.6213 15.0661 11.0289 15.107 10.6215 14.7778L8.37148 12.9596C7.94192 12.6125 7.87509 11.9829 8.22221 11.5533C8.56933 11.1237 9.19896 11.0569 9.62852 11.404L11.1559 12.6383L14.2705 9.31606C14.6482 8.91315 15.281 8.89274 15.6839 9.27047Z"
                                        fill="#1C274C"
                                      ></path>{" "}
                                    </g>
                                  </svg>
                                  <p>Your request has been submitted.</p>
                                  <p>
                                    Thank you for reaching out to{" "}
                                    <span className="gradient-text">
                                      VODIUM
                                    </span>
                                    .
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;
