import { msalInstance } from "..";
import { loginRequest } from "../services/authProvider";
import { CLIENT_ID, CLIENT_SECRET, LOGIN_URL } from "../shared/common/constant";

const logout = () => {
  msalInstance.logoutRedirect();
  console.log("User logged out.");
};

const login = async () => {
  await msalInstance.handleRedirectPromise();
  await msalInstance.loginRedirect(loginRequest);
  console.log("User logged in.");
};

const getBearerToken = async () => {
  const getBearerTokenResponse = await fetch(
    "https://dev-teams-svc.vodium.com/auth/token",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "no-cors",
        Authorization: "",
      },
    }
  );
  const responseData = await getBearerTokenResponse.json();
  const accessToken = responseData.access_token;
  return `Bearer ${accessToken}`;
};

export { logout, login, getBearerToken };
