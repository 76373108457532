import { NavLink } from "react-router-dom";
import "./Header.css";
import { login, logout } from "../services/auth.service";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "../App.css";
import headerLogo from "../assets/vodium_logo_header.svg";

const Header = () => {
  return (
    <nav className="fixed top-0 z-50 w-full bg-[#04254e] hidden xl:block">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start rtl:justify-end">
            <NavLink to="/vodium">
              <div className="flex h-6 ms-3 md:me-24 md:h-8 ">
                <img src={headerLogo} alt="VODIUM" />
              </div>
            </NavLink>
          </div>
          <UnauthenticatedTemplate>
            <button className="white button" onClick={login}>
              Sign In
            </button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <button className="white button" onClick={logout}>
              Sign Out
            </button>
          </AuthenticatedTemplate>
        </div>
      </div>
    </nav>
  );
};

export default Header;
