import { showAlert } from "../shared/Alert";
import { ActionTypes, ACTION_HANDLER_TYPE } from "../shared/common/constant";
import EmailService from "./email.service";
//import SubscriptionService from "./subscribe.service";

const ActionHandler = (type, payload = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      switch (type) {
        case ACTION_HANDLER_TYPE.SEND_EMAIL_CONTACT:
          resolve(await EmailService.sendEmailContact(payload));
          break;
        case ACTION_HANDLER_TYPE.SEND_EMAIL_BUG:
          resolve(await EmailService.sendEmailBug(payload));
          break;
        case ACTION_HANDLER_TYPE.SEND_EMAIL_LICENSE_UPDATE:
          resolve(await EmailService.sendEmailLicenseUpdate(payload));
          break;
        // case ACTION_HANDLER_TYPE.GET_AVAILABLE_SUBSCRIPTION:
        //   resolve(await Subscription.listAvailableSubscription());
        //   break;
        default:
          return Promise.reject("Action Type not found");
      }
    } catch (err) {
      if (err.message) {
        const errorMessage = String(err.message).toLowerCase();
        if (errorMessage.includes("unexpected end of json input")) {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Due to inactivity, you must refresh the page.",
          });
        } else {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Operation failed. " + err.message,
          });
        }
      }
      return Promise.reject(err);
    }
  });
};

export default ActionHandler;
