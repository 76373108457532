import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";

//MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./services/authProvider";
import { setAccessToken } from "./redux/app/app-action";
import { getAccessToken } from "./services/msGraphApiCall";

export const msalInstance = new PublicClientApplication(msalConfig);
const fetchAccessToken = async () => {
  const accessToken = await getAccessToken();
  store.dispatch(setAccessToken(accessToken));
};
msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  fetchAccessToken();
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
        <BrowserRouter>
          <App pca={msalInstance} />
        </BrowserRouter>
      {/* </React.StrictMode> */}
    </Provider>
  );
});
