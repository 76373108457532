import React from "react";
import "./css/Help.css";
import "../App.css";
import { URL_GUIDE } from "../shared/common/constant";
import faqList from "../files/faq.json";

const HelpMobile = () => {
  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [adoptionOpen, setAdoptionOpen] = React.useState(false);
  const [rolloutOpen, setRolloutOpen] = React.useState(false);
  const [instructionsOpen, setInstructionsOpen] = React.useState(false);
  const [guideOpen, setGuideOpen] = React.useState(false);
  const [faqOpen, setFaqOpen] = React.useState(false);
  const [view, setView] = React.useState({
    id: "ABOUT_THE_APP",
    display: "About",
  });
  const [collapsedFaq, setCollapseFaq] = React.useState(
    Array(faqList.length).fill(false)
  );
  const toggleView = (value) => {
    setView(value);
  };
  const toggleCollapse = (index) => {
    const newCollapsedStates = [...collapsedFaq];
    newCollapsedStates[index] = !newCollapsedStates[index];
    setCollapseFaq(newCollapsedStates);
  };
  const actionHandler = async (event) => {
    const id = event.target.id;
    switch (id) {
      case "about":
        setAboutOpen(!aboutOpen);
        toggleView({
          display: "About",
        });
        break;
      case "adoption":
        setAdoptionOpen(!adoptionOpen);
        toggleView({
          display: "Adoption Instructions",
        });
        break;
      case "rollout":
        setRolloutOpen(!rolloutOpen);
        toggleView({
          display: "Proposed Rollout",
        });
        break;
      case "instructions":
        setInstructionsOpen(!instructionsOpen);
        toggleView({
          display: "Instructions",
        });
        break;
      case "guide":
        setGuideOpen(!guideOpen);
        toggleView({
          display: "User Guide",
        });
        break;
      case "faq":
        setFaqOpen(!faqOpen);
        toggleView({
          display: "FAQs",
        });
        break;
      default:
        return;
    }
  };
  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden help-page">
      <main>
        <div className="pt-20 pb-10 md:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="main-content-label">
                VODIUM Support{" "}
                <span className="view-label">/ {view.display}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="px-6 mx-auto search-div">
          <div className="space-y-7 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-5 py-4">
                  <div className="items-center justify-between mt-3">
                    <p className="none-gradient">
                      {" "}
                      Welcome to <span className="gradient-text">
                        VODIUM
                      </span>{" "}
                      Support
                    </p>
                    <p>
                      Frequently asked questions and helpful resources to get
                      the most out of VODIUM
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-6">
          <div id="menubar" className={`col-span-2`}>
            <div className="grid flex-col flex-initial overflow-x-hidden">
              <div className="py-2 mt-2 pb-16">
                <div className="items-start">
                  <div className="px-6 mx-auto">
                    <div className="space-y-5 sm:space-y-6">
                      <div className="grid gap-5">
                        <div>
                          <button
                            id="about"
                            type="button"
                            className={`flex items-center justify-between w-full p-5 text-base font-extrabold lg:text-lg rtl:text-right bg-white border border-gray-200 rounded text-[#5954A4] hover:bg-[#5954A4] hover:text-[#fff] focus:bg-[#5954A4] focus:text-[#fff] gap-3`}
                            onClick={actionHandler}
                          >
                            <span>About</span>
                            <svg
                              className={`${
                                aboutOpen ? "" : "rotate-180"
                              } w-3 h-3 shrink-0`}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5 5 1 1 5"
                              />
                            </svg>
                          </button>

                          <div className={`${aboutOpen ? "flex" : "hidden"}`}>
                            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
                              <p className="mb-2">
                                VODIUM is an easy-to-use teleprompter built to
                                assist and support professionals by allowing
                                them to read off their notes or script while
                                also giving the appearance of looking directly
                                at their meeting attendees.
                              </p>
                            </div>
                          </div>

                          <button
                            id="adoption"
                            type="button"
                            className="flex items-center justify-between w-full p-5 text-base font-extrabold lg:text-lg rtl:text-right bg-white border border-gray-200 rounded text-[#5954A4] hover:bg-[#5954A4] hover:text-[#fff] focus:bg-[#5954A4] focus:text-[#fff] gap-3"
                            onClick={actionHandler}
                          >
                            <span>Adoption Instructions</span>
                            <svg
                              className={`${
                                adoptionOpen ? "" : "rotate-180"
                              } w-3 h-3 shrink-0`}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5 5 1 1 5"
                              />
                            </svg>
                          </button>

                          <div
                            className={`${adoptionOpen ? "flex" : "hidden"}`}
                          >
                            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
                              <p className="mb-4">
                                App adoption not only needs to involve
                                understanding how to technically control VODIUM,
                                but also understanding the desired results.
                                VODIUM is built to help professionals who are in
                                the distributed workspace who conduct important
                                conversations utilizing their computers.
                                Individuals and teams should spend time
                                practicing and prepping with the app by{" "}
                                <a
                                  href="https://www.youtube.com/channel/UCF0Nbhv83aBo8Iw-B-Rmkig"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  helpful videos and tips
                                </a>
                                .
                              </p>
                            </div>
                          </div>
                          <h2>
                            <button
                              id="rollout"
                              type="button"
                              className="flex items-center justify-between w-full p-5 text-base font-extrabold lg:text-lg rtl:text-right bg-white border border-gray-200 rounded text-[#5954A4] hover:bg-[#5954A4] hover:text-[#fff] focus:bg-[#5954A4] focus:text-[#fff] gap-3"
                              onClick={actionHandler}
                            >
                              <span>Proposed Rollout</span>
                              <svg
                                className={`${
                                  rolloutOpen ? "" : "rotate-180"
                                } w-3 h-3 shrink-0`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          <div className={`${rolloutOpen ? "flex" : "hidden"}`}>
                            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
                              <p className="mb-2">
                                A majority of our individual customers use
                                VODIUM for presentations, pre-recordings, and or
                                interviews. Businesses use the app to plan
                                multi-person presentations on video, or webcast
                                teams who virtually support hundreds of internal
                                and external speakers. We at VODIUM propose a
                                time to onboard each user on the app. Then allow
                                for plenty of practice and prep sessions to feel
                                confident on how to control the app while
                                focusing on delivery.
                              </p>
                            </div>
                          </div>
                          <h2>
                            <button
                              id="instructions"
                              type="button"
                              className="flex items-center justify-between w-full p-5 text-base font-extrabold lg:text-lg rtl:text-right bg-white border border-gray-200 rounded text-[#5954A4] hover:bg-[#5954A4] hover:text-[#fff] focus:bg-[#5954A4] focus:text-[#fff] gap-3"
                              onClick={actionHandler}
                            >
                              <span>Instructions</span>
                              <svg
                                className={`${
                                  instructionsOpen ? "" : "rotate-180"
                                } w-3 h-3 shrink-0`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          <div
                            className={`${
                              instructionsOpen ? "flex" : "hidden"
                            }`}
                          >
                            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
                              <p className="mb-4">
                                We at VODIUM are a team of trained experts in
                                the video communication space. We recommend that
                                admins assist users on how to add the app into
                                the Microsoft Teams experience. We also
                                recommend that admins encourage practice and
                                prep on how to utilize the app during a
                                presentation, pre-recorded video, meeting, etc.
                              </p>
                              <p className="mb-2">
                                Users can access VODIUM in the Tabs experience,
                                and pop-out the app to position it near the
                                computer’s camera. Users can also utilize the
                                teleprompter during the In-Meeting experience,
                                and can position the app towards the middle of
                                the computer screen to look like you’re looking
                                at the attendees.
                              </p>
                            </div>
                          </div>
                          <h2>
                            <button
                              id="guide"
                              type="button"
                              className="flex items-center justify-between w-full p-5 text-base font-extrabold lg:text-lg rtl:text-right bg-white border border-gray-200 rounded text-[#5954A4] hover:bg-[#5954A4] hover:text-[#fff] focus:bg-[#5954A4] focus:text-[#fff] gap-3"
                              onClick={actionHandler}
                            >
                              <span>User Guide</span>
                              <svg
                                className={`${
                                  guideOpen ? "" : "rotate-180"
                                } w-3 h-3 shrink-0`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          <div className={`${guideOpen ? "flex" : "hidden"}`}>
                            <div className="p-5 w-full border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
                              <iframe title="User Guide" src={"https://drive.google.com/viewerng/viewer?embedded=true&url="+URL_GUIDE} />
                            </div>
                          </div>
                          <h2>
                            <button
                              id="faq"
                              type="button"
                              className="flex items-center justify-between w-full p-5 text-base font-extrabold lg:text-lg rtl:text-right bg-white border border-gray-200 rounded text-[#5954A4] hover:bg-[#5954A4] hover:text-[#fff] focus:bg-[#5954A4] focus:text-[#fff] gap-3"
                              onClick={actionHandler}
                            >
                              <span>FAQs</span>
                              <svg
                                className={`${
                                  faqOpen ? "" : "rotate-180"
                                } w-3 h-3 shrink-0`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          <div className={`${faqOpen ? "flex" : "hidden"}`}>
                            <div className="p-5 w-full border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
                              {faqList.map((item, index) => (
                                <div
                                  key={item.id}
                                  className={` faq-div col ${
                                    !collapsedFaq[index]
                                      ? "faq-uncollapsed"
                                      : "faq-collapsed"
                                  }`}
                                >
                                  <div
                                    onClick={() => toggleCollapse(index)}
                                    className="faq-question-div"
                                  >
                                    <span className="faq-question">
                                      {item.question}
                                    </span>
                                    {!collapsedFaq[index] ? (
                                      <span className="faq-question-icon">
                                        +
                                      </span>
                                    ) : (
                                      <span className="faq-question-icon">
                                        <hr className="long-dash" />
                                      </span>
                                    )}
                                  </div>
                                  {!collapsedFaq[index] ? null : (
                                    <div className="faq-answer">
                                      {item.answer}
                                      <br />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HelpMobile;
