import React from "react";
import "../App.css";
import "./css/Vodium.css";
import WelcomeName from "../components/WelcomeName";
import { URL_FEATURES } from "../shared/common/constant";

const Vodium = () => {
  function openFeatures() {
    window.open(URL_FEATURES, "_blank");
  }

  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="pt-24 pb-10 lg:pt-28">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex vodium">
              <WelcomeName viewpage={"VODIUM"} />
            </div>
          </div>
        </div>

        <div className="px-6 mx-auto">
          <div className="space-y-5 sm:space-y-6">
            <div className="grid gap-5">
              <div className="bg-white border border-gray-200 rounded">
                <div className="px-10 py-10">
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-4">
                    <div className="col-span-1 md:col-span-2">
                      <span>
                        <p className="title-description text-lg font-semibold md:text-2xl">
                          If you're on video, you need VODIUM.
                        </p>
                        <p className="description text-md font-light md:text-lg">
                          Inspired by a teleprompter used in newscasting and
                          national addresses, VODIUM is an easy-to-use software
                          with a design and functionality built to equip you
                          with what it takes to be an effective communicator on
                          video.{" "}
                        </p>
                        <button
                          className="blue button view-button"
                          onClick={() => openFeatures()}
                        >
                          View Features
                        </button>
                      </span>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                      <video
                        width="585"
                        height=""
                        muted="”muted”"
                        preload="yes"
                        autoPlay="autoPlay"
                        loop="loop"
                      >
                        <source src="https://19991872.fs1.hubspotusercontent-na1.net/hubfs/19991872/VODIUM-FEATURES%20(New-RTF).mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Vodium;
