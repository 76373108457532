import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isLoggedIn, isAppInit, children }) => {
	if(!isAppInit) {
		return null;
	}
	if (!isLoggedIn && isAppInit) {
		return <Navigate to="/" replace />;
	}
	return children;
};

export default ProtectedRoute;